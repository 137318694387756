<template>
  <div class="all">
    <!-- 导航 -->
    <van-nav-bar title="我的评价" left-arrow @click-left="onClickLeft" />
    <div class="container">
       <div class="container2" v-if="!commentInfo.length">
          <div class="no-comment">
            <!-- 缺省页_暂无缴费@2x.png -->
            <img src="./pingjia.png"/>
            <span>暂无评价</span>
          </div>
       </div>
       <div class="comment" v-for="(item, index) in this.commentInfo" :key="index">
        <div class="user-info">
          <div class="img">
            <img :src="item.headimgUrl" />
          </div>
          <div class="names">
            <span style="margin-top: -2px">{{item.nickName}}</span>
            <span style="margin-top: 3px;color: #999999;">{{item.createTime.substr(0,11)}} 评论</span>
          </div>
        </div>
        <div class="comment-content">
          <span>{{item.content}}</span>
        </div>
        <div class="others">
          <span>商品：{{item.goodsScore}}分</span>
          <span style="margin-left: 20px">物流：{{item.logisticsScore}}分</span>
          <span style="margin-left: 20px">服务：{{item.serviceScore}}分</span>
        </div>
        <div class="item-info">
          <img
            style="width: 80px; height: 80px"
            :src="item.picUrls"
            alt
          />
          <div class="desc-spec">
            <div class="desc">
              <span style="display: inline-block; width: 180px"
                >{{item.spuName}}</span
              >
              <span></span>
            </div>
            <div class="spec">
              <span>规格：{{item.specInfo}}</span>
              <span></span>
            </div>
          </div>
        </div>
        <div class="answer">
          <span class="merchant">商家回复</span><span>{{item.sellerReply}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCommentInfo } from "@/api/comment";
export default {
  data() {
    return {
      commentInfo: []
    };
  },
  created() {
    this.$store.commit("setSession", this.$route.query.session)
    this.getCommentInfo();
  },
  methods: {
    getCommentInfo() {
      getCommentInfo().then((res) => {
        if (res.data.code === 0) {
          this.commentInfo = res.data.data.records
        }
      });
    },
    // 返回上一页
    onClickLeft() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar {
  position: fixed;
  height: 46px;
  width: 100%;
  top: 0;
  margin-bottom: 15px;
}
.container {
  height: 100vh;
  width: 100%;
  background-color: #fdfcfc;
  overflow-y: auto;
  margin-top: 60px;
  .container2{
    .no-comment {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      span {
        font-size: 18px;
      }
    }
  }
  .comment {
    // box-shadow: 10px 10px 5px #f5f3f3;
    margin-bottom: 20px;
    width: 100%;
    // height: 300px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 15px;
    .user-info {
      display: flex;
      //   margin-top: -5px;
      .img {
        width: 40px;
        height: 40px;
        // border: 1px solid pink;
        border-radius: 50%;
      }
      .names {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
      }
    }
    .comment-content {
      margin-top: 15px;
    }
    .others {
      margin-top: 10px;
      span {
        color: #999999;
      }
    }
    .item-info {
      margin-top: 20px;
      display: flex;
      .desc-spec {
        margin-left: 10px;
        flex: 1;
        .desc {
          display: flex;
          justify-content: space-between;
        }
        .spec {
          margin-top: 10px;
          color: #999999;
          font-family: PingFang-SC-Regular;
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .answer {
      padding-top: 15px;
      .merchant {
        margin-right: 20px;
        color: #999999;
      }
    }
  }
}
</style>